<template>
  <select :disabled="disabled" class="custom-select" v-model="selectedDepot">
    <option
      v-for="(option, index) in depotOptions"
      :key="index"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import StorageService from '@/common/storage-service';
import DepotApi from '@WS/api/depot';

const ALL_DEPOTS_ID = '*';

export default {
  props: {
    disabled: false,
  },
  computed: {
    ...mapState('system', {
      depots: (state) => state.depot.list,
    }),

    ...mapState('auth', {
      rememberMe: (state) => state.rememberMe,
    }),

    ...mapGetters('system', ['currentDepotId']),

    depotOptions() {
      const options = this.depots.map((depot) => {
        let text = `${depot.Id} - ${depot.Name}`;
        if (depot.ShortName && depot.Name != depot.ShortName) {
          text += ` (${depot.ShortName})`;
        }
        if (ALL_DEPOTS_ID === depot.Id) {
          text = depot.Name;
        }
        return {
          value: depot.Id,
          text: text,
        };
      });
      return options;
    },

    selectedDepot: {
      get() {
        return this.currentDepotId;
      },
      set(depotId) {
        this.setDepot({
          depotId,
          rememberMe: this.rememberMe,
        });
      },
    },
  },

  methods: {
    ...mapActions('system', {
      setDepot: 'setCurrentDepot',
    }),
  },
};
</script>
