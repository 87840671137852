/**
 * For information about building the Font Awesome library
 * please refer to the following documentation:
 *
 * https://github.com/FortAwesome/vue-fontawesome#why-use-the-concept-of-a-library
 */
import {
    faAlignLeft,
    faAngleRight,
    faAngleDoubleRight,
    faBox,
    faBuilding,
    faCaretDown,
    faCaretRight,
    faChartBar,
    faCheckCircle,
    faCircle,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faCode,
    faCog,
    faChevronRight,
    faChevronLeft,
    faEllipsisH,
    faEllipsisV,
    faExclamationTriangle,
    faFileAlt,
    faHistory,
    faListAlt,
    faMapMarkedAlt,
    faOilCan,
    faPlus,
    faRoad,
    faSearch,
    faSignature,
    faSyncAlt,
    faTimes,
    faTools,
    faTractor,
    faUser,
    faUsers,
    faUserTie,
    faWrench,
    faShoppingBasket,
    faEdit,
    faSignOutAlt,
    faSignInAlt,
    faSlidersH,
    faRandom,
    faShoppingCart,
    faEye,
    faCartPlus,
    faPrint
} from '@fortawesome/free-solid-svg-icons';

import { faTrello } from '@fortawesome/free-brands-svg-icons';

const IconDefinitions = [
    faAlignLeft,
    faAngleRight,
    faAngleDoubleRight,
    faBox,
    faBuilding,
    faCaretDown,
    faCaretRight,
    faChartBar,
    faCheckCircle,
    faCircle,
    faClipboardCheck,
    faClipboardList,
    faClock,
    faCode,
    faCog,
    faChevronRight,
    faChevronLeft,
    faEllipsisH,
    faEllipsisV,
    faExclamationTriangle,
    faFileAlt,
    faHistory,
    faListAlt,
    faMapMarkedAlt,
    faOilCan,
    faPlus,
    faRoad,
    faSearch,
    faSignature,
    faSyncAlt,
    faTimes,
    faTools,
    faTractor,
    faTrello,
    faUser,
    faUsers,
    faUserTie,
    faWrench,
    faShoppingBasket,
    faEdit,
    faSignOutAlt,
    faSignInAlt,
    faSlidersH,
    faRandom,
    faShoppingCart,
    faEye,
    faCartPlus,
    faPrint
];

export { IconDefinitions };
