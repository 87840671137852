<template>
  <div class="row h-100">
    <div class="col-md-12 h-100">
      <IbRolesRequired v-if="isRoleRequired" />
      <router-view v-else class="h-100" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import * as mutation from '@/store/mutation-types';
import IbRolesRequired from '@/components/IbRequiredRoles.vue';

const DEFAULT_CONFIG = {
  Id: 0,
  DisplayName: 'No Filters Selected',
  IsDefault: true,
  PersistenceTypeId: 2,
  PersistedSetting: {
    WarrantyJobsOnly: false,
    NonGoldServiceEngineersOnly: false,
    DocumentsToApproveOnly: false,
    MultipleEngineersOnly: false,
    PartsOnOrderOnly: false,
    HideRepeatable: false,
    InvoiceType: [],
    GoldStatus: '',
    Department: [],
    Columns: [],
  },
};

export default {
  name: 'Workshop',
  components: { IbRolesRequired },

  /**
   * Fetch the jobs when the component is loaded in.
   */
  created() {
    this.fetchJobs();
  },

  /**
   * Clear down state before destroying component.
   */
  beforeDestroy() {
    this.clearEngineers();
  },

  computed: {
    ...mapGetters('system', {
      companyId: 'currentCompanyId',
      depotId: 'currentDepotId',
      isRoleRequired: 'isRoleRequired',
    }),
    ...mapGetters('kanban', {
      cachedFilterConfig: 'filterConfig',
    }),
  },

  watch: {
    companyId: {
      immediate: true,
      handler(newValue) {
        // Reload the engineers and the jobs when
        // the selected company changes.
        if (newValue) {
          this.fetchEngineers();
          this.fetchJobs();
        }
      },
    },

    depotId: {
      immediate: true,
      handler(newValue) {
        // Reload the jobs when the selected depot changes.
        if (newValue) {
          this.fetchJobs();
        }
      },
    },
  },

  methods: {
    ...mapMutations('engineer', {
      clearEngineers: mutation.RESET,
    }),

    ...mapActions('engineer', {
      fetchEngineers: 'fetchEngineers',
    }),

    ...mapActions('job', {
      fetchActiveJobs: 'fetchActiveJobs',
      fetchLoggedJobs: 'fetchLoggedJobs',
      clearJobs: 'clearJobs',
    }),

    fetchJobs() {
      if (this.cachedFilterConfig.Id === undefined) {
        this.fetchActiveJobs({
          depot: this.depotId,
          config: DEFAULT_CONFIG,
        });
        this.fetchLoggedJobs({
          depot: this.depotId,
          config: DEFAULT_CONFIG,
        });
      }
    },
  },
};
</script>
