import { reset }        from '../mutators';
import * as mutation    from '../mutation-types';

const initialState = () => ({
    columnData : [],
    filterConfig: {}
});

const getters = {

    columnData: state => {
        return state.columnData;
    },
    filterConfig: state => {
        return state.filterConfig;
    }

};

const mutations = {
    [mutation.SET_COLUMN_DATA       ] : (state, data) => {
        state.columnData[data.id] = data.value;
    },
    [mutation.SET_FILTER_CONFIG       ] : (state, data) => {
        state.filterConfig = data;
    },

    [mutation.CLEAR_COLUMN_DATA     ] : (state, id) => {
        state.columnData[id] = null;
    },

    [mutation.CLEAR_ALL_COLUMN_DATA ] : (state) => state.columnData = [],
    [mutation.RESET                 ] : (state) => reset(state, initialState()),
};

const actions = {

    /**
     * Reset the store module to its initial state.
     */
    reset({ commit }) {
        commit(mutation.RESET);
    },

    /**
     * Store the current column data into the vuex store.
     * @param {*} data - the column data.
     */
    storeColumnData({ commit }, data) {
        commit(mutation.SET_COLUMN_DATA, data);
    },
    
    storeFilterConfig({ commit }, data) {
        commit(mutation.SET_FILTER_CONFIG, data);
    },

};

export default {
    namespaced : true,
    state      : initialState(),
    getters,
    mutations,
    actions
}