import {
    filter as _filter,
    map    as _map,
}                       from 'lodash';
import JobApi           from '@/modules/workshop/api/job';
import JobStateApi      from '@/modules/workshop/api/job-state';
import ChecklistApi     from '@/modules/workshop/api/checklist';
import WholegoodApi     from '@/modules/workshop/api/wholegood';
import { set, reset }   from '../mutators';
import * as mutation    from '../mutation-types';

const NOT_FOUND_STATUS = 404;

/**
 * Add engineer name (matching user ID) as a new property to
 * job data records.
 * @param {Object} getters - Vuex store getters.
 * @param {Object[]} records - Array of data records.
 */
function addEngineerName(getters, records) {
    const updatedRecords = _map(records, record => {
        return {
            ...record,
            EngineerName: getters.getEngineerNameByApplicationUserId(record.UserId)
        };
    });
    return updatedRecords;
}

const initialState = () => ({	
    job         : {
        value	    : null,
        loading	    : false,
        error	    : null,
        data        : {
            loading       : false,
            mileages      : {
                value   : [],
                loading : false,
                error   : null
            },
            notes         : {
                value   : [],
                loading : false,
                error   : null
            },
            serialNumbers : {
                value   : [],
                loading : false,
                error   : null
            },
            signatures    : {
                value   : [],
                loading : false,
                error   : null
            },
            clockHours    : {
                value   : [],
                loading : false,
                error   : null
            },
            engineers : {
                value   : [],
                loading : false,
                error   : null
            },
            comments : {
                value   : [],
                loading : false,
                error   : null
            }

        }
    },

    tasks       : {
        value	    : [],
        loading     : false,
        error	    : null
    },

    activities  : {
        value	    : [],
        loading     : false,
        error	    : null
    },

    completions : {
        value	    : [],
        loading     : false,
        error	    : null
    },

    activeJobs  : {
        value       : [],
        loading     : false,
        error       : null
    },

    loggedJobs  : {
        value       : [],
        loading     : false,
        error       : null
    },

    states      : {
        value       : [],
        loading     : false,
        error       : null
    },

    checklists      : {
        value       : [],
        loading     : false,
        error       : null
    },

    filters     : {
        jobsKanban  : null
    },

    customerWholegoods : {
        value   : [],
        loading : false,
        error   : null
    },
});

const getters = {
	job: state => {
        return state.job.value;
    },

    jobLoading: state => {
        return state.job.loading;
    },

    jobError: state => {
        return state.job.error;
    },

    jobDataLoading: state => {
        return state.job.data.loading;
    },

    tasks: state => {
        return state.tasks.value;
    },

    activities: state => {
        return state.activities.value;
    },

    activitiesLoading: state => {
        return state.activities.loading;
    },

    completions: state => {
        return state.completions.value;
    },

    activeJobs: state => {
        return state.activeJobs.value;
    },

    activeJobsError: state => {
        return state.activeJobs.error;
    },

    activeJobsLoading: state => {
        return state.activeJobs.loading;
    },

    loggedJobs: state => {
        return state.loggedJobs.value; 
    },

    loggedJobsError: state => {
        return state.loggedJobs.error;
    },

    loggedJobsLoading: state => {
        return state.loggedJobs.loading;    
    },

    jobsKanbanFilter: state => {
        return state.filters.jobsKanban;
    },

    subStates: state => {
        return state.states.value;
    },

    subStatesLoading: state => {
        return state.states.loading;
    },

    subStatesError: state=> {
        return state.states.error;
    },

    checklists: state => {
        return state.checklists.value;
    },

    checklistsLoading: state => {
        return state.checklists.loading;
    },

    checklistsError: state=> {
        return state.checklists.error;
    },

    checklistById: (state) => id => {
        return _.find(state.checklists.value, c => c.Id === id);
    },

    jobErrorMessage: state => {
        const error = state.job.error;
        if (!error) {
            return null;
        }

        const responseStatus       = _.get(error, 'response.status');
        const responseErrorMessage = _.get(error, 'response.data.message');

        // Default to generic response error message.
        let message = error.message;
        // Handle not found.
        if (NOT_FOUND_STATUS === responseStatus) {
            message = 'Job not found!';
        }
        // Get error from API.
        else if (responseErrorMessage) {
            message = responseErrorMessage;
        }

        return message
    },

    getEngineersClockHours: (state) => (engineer) => {
        return _.find(state.job.data.clockHours.value, record => engineer.UserId === record.UserId) || null;
    },

    getEngineersMileage: (state) => (engineer) => {
        return _.find(state.job.data.mileages.value, record => engineer.UserId === record.UserId) || null;
    },

    getEngineersSerialNumber: (state) => (engineer) => {
        return _.find(state.job.data.serialNumbers.value, record => engineer.UserId === record.UserId) || null;
    },

    getSerialNumbers: (state) => {
        return state.job.data.serialNumbers.value;
    },

    getEngineersPartsNotes: (state) => (engineer) => {
        const PARTS_NOTES_TYPE = 0;
        return _.find(state.job.data.notes.value, record => engineer.UserId  === record.UserId &&
                                                            PARTS_NOTES_TYPE === record.Type) || null;
    },

    getEngineersMarkdown: (state) => (engineer) => {
        const MARKDOWN_NOTES_TYPE = 1;
        return _.find(state.job.data.notes.value, record => engineer.UserId     === record.UserId &&
                                                            MARKDOWN_NOTES_TYPE === record.Type) || null;
    },

    getEngineersSignatures: (state) => (engineer) => {
        return _.filter(state.job.data.signatures.value, record => engineer.UserId === record.UserId) || [];
    },

    getEngineersComments: (state) => (engineer) => {
        return _.filter(state.job.data.comments.value, record => engineer.UserId === record.UserId) || [];
    },

    getEngineersActivities: (state) => (engineer) => {
        return _.filter(state.activities.value, record => engineer.Id === record.EngineerId) || [];
    },

    getEngineers: (state) => {
        return state.job.data.engineers.value;
    },

    getEngineerByApplicationUserId: (state) => (id) => {
        return _.find(state.job.data.engineers.value, engineer => id === engineer.UserId);
    },

    getEngineerById: (state) => (id) => {
        return _.find(state.job.data.engineers.value, engineer => id === engineer.Id);
    },

    getEngineerNameByApplicationUserId: (state, getters) => (id) => {
        const engineer = getters.getEngineerByApplicationUserId(id);
        const name     = _.get(engineer, 'Name', 'Unknown');
        return name;
    },

    getCustomerWholegoods: (state) => {
        return state.customerWholegoods.value;
    },

    partsNotes: (state) => {
        const PARTS_NOTES_TYPE = 0;
        return _.filter(state.job.data.notes.value, record => PARTS_NOTES_TYPE === record.Type);
    },

    storyNotes: (state) => {
        const MARKDOWN_NOTES_TYPE = 1;
        return _.filter(state.job.data.notes.value, record => MARKDOWN_NOTES_TYPE === record.Type);
    },

    comments: (state) => {
        return state.job.data.comments.value;
    },
};

const mutations = {
    // Set state.
    [mutation.SET_JOB                         ] : set('job.value',                        'job'           ),
    [mutation.SET_JOB_LOADING                 ] : set('job.loading',                      'loading'       ),
    [mutation.SET_JOB_ERROR                   ] : set('job.error',                        'error'         ),
    [mutation.SET_JOB_DATA_LOADING            ] : set('job.data.loading',                 'loading'       ),
    [mutation.SET_TASKS                       ] : set('tasks.value',                      'tasks'         ),
    [mutation.SET_TASKS_LOADING               ] : set('tasks.loading',                    'loading'       ),
    [mutation.SET_TASKS_ERROR                 ] : set('tasks.error',                      'error'         ),
    [mutation.SET_ACTIVITIES                  ] : set('activities.value',                 'activities'    ),
    [mutation.SET_ACTIVITIES_LOADING          ] : set('activities.loading',               'loading'       ),
    [mutation.SET_ACTIVITIES_ERROR            ] : set('activities.error',                 'error'         ),
    [mutation.SET_COMPLETIONS                 ] : set('completions.value',                'completions'   ),
    [mutation.SET_COMPLETIONS_LOADING         ] : set('completions.loading',              'loading'       ),
    [mutation.SET_COMPLETIONS_ERROR           ] : set('completions.error',                'error'         ),
    [mutation.SET_ACTIVE_JOBS                 ] : set('activeJobs.value',                 'jobs'          ),
    [mutation.SET_ACTIVE_JOBS_ERROR           ] : set('activeJobs.error',                 'error'         ),
    [mutation.SET_ACTIVE_JOBS_LOADING         ] : set('activeJobs.loading',               'loading'       ),
    [mutation.SET_LOGGED_JOBS                 ] : set('loggedJobs.value',                 'jobs'          ),
    [mutation.SET_LOGGED_JOBS_ERROR           ] : set('loggedJobs.error',                 'error'         ),
    [mutation.SET_LOGGED_JOBS_LOADING         ] : set('loggedJobs.loading',               'loading'       ),
    [mutation.SET_JOBS_KANBAN_SEARCH          ] : set('filters.jobsKanban',               'filter'        ),
    [mutation.SET_JOB_SUB_STATES              ] : set('states.value',                     'states'        ),
    [mutation.SET_JOB_SUB_STATES_LOADING      ] : set('states.loading',                   'loading'       ),
    [mutation.SET_JOB_SUB_STATES_ERROR        ] : set('states.error',                     'error'         ),
    [mutation.SET_CHECKLISTS                  ] : set('checklists.value',                 'checklists'    ),
    [mutation.SET_CHECKLISTS_LOADING          ] : set('checklists.loading',               'loading'       ),
    [mutation.SET_CHECKLISTS_ERROR            ] : set('checklists.error',                 'error'         ),
    [mutation.SET_MILEAGES                    ] : set('job.data.mileages.value',          'mileages'      ),
    [mutation.SET_MILEAGES_LOADING            ] : set('job.data.mileages.loading',        'loading'       ),
    [mutation.SET_MILEAGES_ERROR              ] : set('job.data.mileages.error',          'error'         ),
    [mutation.SET_NOTES                       ] : set('job.data.notes.value',             'notes'         ),
    [mutation.SET_NOTES_LOADING               ] : set('job.data.notes.loading',           'loading'       ),
    [mutation.SET_NOTES_ERROR                 ] : set('job.data.notes.error',             'error'         ),
    [mutation.SET_SERIAL_NUMBER               ] : set('job.data.serialNumbers.value',     'serialNumbers' ),
    [mutation.SET_SERIAL_NUMBER_LOADING       ] : set('job.data.serialNumbers.loading',   'loading'       ),
    [mutation.SET_SERIAL_NUMBER_ERROR         ] : set('job.data.serialNumbers.error',     'error'         ),
    [mutation.SET_SIGNATURES                  ] : set('job.data.signatures.value',        'signatures'    ),
    [mutation.SET_SIGNATURES_LOADING          ] : set('job.data.signatures.loading',      'loading'       ),
    [mutation.SET_SIGNATURES_ERROR            ] : set('job.data.signatures.error',        'error'         ),
    [mutation.SET_CLOCK_HOURS                 ] : set('job.data.clockHours.value',        'clockHours'    ),
    [mutation.SET_CLOCK_HOURS_LOADING         ] : set('job.data.clockHours.loading',      'loading'       ),
    [mutation.SET_CLOCK_HOURS_ERROR           ] : set('job.data.clockHours.error',        'error'         ),
    [mutation.SET_ENGINEERS                   ] : set('job.data.engineers.value',         'engineers'     ),
    [mutation.SET_ENGINEERS_LOADING           ] : set('job.data.engineers.loading',       'loading'       ),
    [mutation.SET_ENGINEERS_ERROR             ] : set('job.data.engineers.error',         'error'         ),
    [mutation.SET_CUSTOMER_WHOLEGOODS         ] : set('customerWholegoods.value',         'wholegoods'    ),
    [mutation.SET_CUSTOMER_WHOLEGOODS_LOADING ] : set('customerWholegoods.loading',       'loading'       ),
    [mutation.SET_CUSTOMER_WHOLEGOODS_ERROR   ] : set('customerWholegoods.error',         'error'         ),
    [mutation.SET_COMMENTS                    ] : set('job.data.comments.value',          'comments'      ),
    [mutation.SET_COMMENTS_LOADING            ] : set('job.data.comments.loading',        'loading'       ),
    [mutation.SET_COMMENTS_ERROR              ] : set('job.data.comments.error',          'error'         ),
    [mutation.UPDATE_CHECKLIST          ]   (checklist){
        const currentChecklists = get('checklists.value');
        const updatedChecklists = currentChecklists.map(cl => cl.Id === checklist.Id ? checklist : cl);
        set('checklists.value', updatedChecklists )
    },
    // Clear state.
    [mutation.CLEAR_JOB                       ] : (state) => state.job.value                    = null,
    [mutation.CLEAR_JOB_ERROR                 ] : (state) => state.job.error                    = null,
    [mutation.CLEAR_TASKS                     ] : (state) => state.tasks.value                  = [],
    [mutation.CLEAR_TASKS_ERROR               ] : (state) => state.tasks.error                  = null,
    [mutation.CLEAR_ACTIVITIES                ] : (state) => state.activities.value             = [],
    [mutation.CLEAR_ACTIVITIES_ERROR          ] : (state) => state.activities.error             = null,
    [mutation.CLEAR_COMPLETIONS               ] : (state) => state.completions.value            = [],
    [mutation.CLEAR_COMPLETIONS_ERROR         ] : (state) => state.completions.error            = null,
    [mutation.CLEAR_ACTIVE_JOBS               ] : (state) => state.activeJobs.value             = [],
    [mutation.CLEAR_ACTIVE_JOBS_ERROR         ] : (state) => state.activeJobs.error             = null,
    [mutation.CLEAR_LOGGED_JOBS               ] : (state) => state.loggedJobs.value             = [],
    [mutation.CLEAR_LOGGED_JOBS_ERROR         ] : (state) => state.loggedJobs.error             = null,
    [mutation.CLEAR_JOBS_KANBAN_SEARCH        ] : (state) => state.filters.jobsKanban           = null,
    [mutation.CLEAR_JOB_SUB_STATES            ] : (state) => state.states.value                 = [],
    [mutation.CLEAR_JOB_SUB_STATES_ERROR      ] : (state) => state.states.error                 = null,
    [mutation.CLEAR_CHECKLISTS                ] : (state) => state.checklists.value             = [],
    [mutation.CLEAR_CHECKLISTS_ERROR          ] : (state) => state.checklists.error             = null,
    [mutation.CLEAR_MILEAGES                  ] : (state) => state.job.data.mileages.value      = [],
    [mutation.CLEAR_MILEAGES_ERROR            ] : (state) => state.job.data.mileages.error      = null,
    [mutation.CLEAR_NOTES                     ] : (state) => state.job.data.notes.value         = [],
    [mutation.CLEAR_NOTES_ERROR               ] : (state) => state.job.data.notes.error         = null,
    [mutation.CLEAR_SERIAL_NUMBER             ] : (state) => state.job.data.serialNumbers.value = [],
    [mutation.CLEAR_SERIAL_NUMBER_ERROR       ] : (state) => state.job.data.serialNumbers.error = null,
    [mutation.CLEAR_SIGNATURES                ] : (state) => state.job.data.signatures.value    = [],
    [mutation.CLEAR_SIGNATURES_ERROR          ] : (state) => state.job.data.signatures.error    = null,
    [mutation.CLEAR_CLOCK_HOURS               ] : (state) => state.job.data.clockHours.value    = [],
    [mutation.CLEAR_CLOCK_HOURS_ERROR         ] : (state) => state.job.data.clockHours.error    = null,
    [mutation.CLEAR_ENGINEERS                 ] : (state) => state.job.data.engineers.value     = [],
    [mutation.CLEAR_ENGINEERS_ERROR           ] : (state) => state.job.data.engineers.error     = null,
    [mutation.CLEAR_CUSTOMER_WHOLEGOODS       ] : (state) => state.customerWholegoods.value     = [],
    [mutation.CLEAR_CUSTOMER_WHOLEGOODS_ERROR ] : (state) => state.customerWholegoods.error     = null,
    [mutation.CLEAR_COMMENTS                  ] : (state) => state.job.data.comments.value      = [],
    [mutation.CLEAR_COMMENTS_ERROR            ] : (state) => state.job.data.comments.error      = null,
    // Reset state.
    [mutation.RESET                     ] : (state) => reset(state, initialState()),
};

const actions = {
    /**
     * Fetch a job by ID from the API and store in vuex.
     * Fetches and stores additional job related data.
     * 
     * @param {string} jobId - The ID of the job.
     */
    async fetchJob({ dispatch, commit }, jobId) {
        commit(mutation.CLEAR_JOB_ERROR);
        commit(mutation.SET_JOB_LOADING, { loading: true });

        try {
            const response = await JobApi.getJobById(jobId);
            const job      = response.data.data;
            
            commit(mutation.SET_JOB, { job });
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.SET_JOB_ERROR, { error: error });
        }
        finally {
            commit(mutation.SET_JOB_LOADING, { loading: false });
        }

        await dispatch('fetchJobData');
    },

    /**
     * Fetches and stores additional data relating to the
     * currently loaded job.
     */
    async fetchJobData({ dispatch, commit, state }) {
        const job = state.job.value;
        // Only load data if a job has been loaded.
        if (job) {
            commit(mutation.SET_JOB_DATA_LOADING, { loading: true });

            await dispatch('fetchCustomerWholegoods', job.AccountNo );
            await dispatch('fetchEngineers',          job.Id        );
            await dispatch('fetchCompletionRecords',  job.Id        );
            await dispatch('fetchTasks',              job.Id        );
            await dispatch('fetchJobActivities',      job.Id        );
            await dispatch('fetchSubStates',          job.Status    );
            await dispatch('fetchChecklists',         job.Id        );
            await dispatch('fetchMileage',            job.Id        );
            await dispatch('fetchNotes',              job.Id        );
            await dispatch('fetchSerialNumbers',      job.Id        );
            await dispatch('fetchSignatures',         job.Id        );
            await dispatch('fetchClockHours',         job.Id        );
            await dispatch('fetchComments',           job.Id        );

            commit(mutation.SET_JOB_DATA_LOADING, { loading: false });
        }
    },

    /**
     * Fetches all completion records for a specified job
     * and stores them in vuex.
     * 
     * @param {string} jobId - The ID of the job.
     */
    async fetchCompletionRecords({ commit }, jobId) {
        commit(mutation.CLEAR_COMPLETIONS_ERROR);
        commit(mutation.SET_COMPLETIONS_LOADING, { loading: true });

        try {
            const response = await JobApi.getCompletionRecords(jobId);
            commit(mutation.SET_COMPLETIONS, { completions: response.data.data });
        }
        catch (error) {
            commit(mutation.CLEAR_COMPLETIONS);
            commit(mutation.SET_COMPLETIONS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_COMPLETIONS_LOADING, { loading: false });
        }
    },

    /**
     * Fetches all tasks (wdts) for a specified job and
     * stores them in vuex.
     * 
     * @param {string} jobId - The ID of the job.
     */
    async fetchTasks({ commit }, jobId) {
        commit(mutation.CLEAR_TASKS_ERROR);
        commit(mutation.SET_TASKS_LOADING, { loading: true });

        try {
            // Temporarily set tasks from job. Once a JobTask endpoints has been
            // created use fetchJobTasks action to load tasks.
            commit(mutation.SET_TASKS, { tasks: job.Tasks });
            // const response = await JobApi.getCompletionRecords(jobId);
            // commit(SET_TASKS, { tasks: response.data.data });
        }
        catch (error) {
            commit(mutation.CLEAR_TASKS);
            commit(mutation.SET_TASKS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_TASKS_LOADING, { loading: false });
        }
    },

    /**
     * Fetches all job activities for a specified job and
     * stores them in vuex.
     * 
     * @param {string} jobId - The ID of the job.
     */
    async fetchJobActivities({ commit }, jobId) {
        commit(mutation.CLEAR_ACTIVITIES_ERROR);
        commit(mutation.SET_ACTIVITIES_LOADING, { loading: true });

        try {
            const response = await JobApi.getActivities(jobId);
            commit(mutation.SET_ACTIVITIES, { activities: response.data.data });
        }
        catch (error) {
            commit(mutation.CLEAR_ACTIVITIES);
            commit(mutation.SET_ACTIVITIES_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_ACTIVITIES_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all active jobs for a specified depot and store 
     * them in vuex.
     * 
     * @param {string} depot - the depot ID.
     */
    async fetchActiveJobs({ commit }, {depot, config}) {
        commit(mutation.CLEAR_ACTIVE_JOBS_ERROR);
        commit(mutation.SET_ACTIVE_JOBS_LOADING, { loading: true });

        try {
            const response = await JobApi.getActiveJobs(depot, config);

            commit(mutation.SET_ACTIVE_JOBS, { jobs: response.data.data });
        }
        catch (error) {
            commit(mutation.CLEAR_ACTIVE_JOBS);
            commit(mutation.SET_ACTIVE_JOBS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_ACTIVE_JOBS_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all logged jobs for a specified depot and store
     * them in vuex.
     * 
     * @param {string} depot - the depot ID.
     */
    async fetchLoggedJobs({ commit }, {depot, config}) {
        commit(mutation.CLEAR_LOGGED_JOBS_ERROR);
        commit(mutation.SET_LOGGED_JOBS_LOADING, { loading: true });

        try {
            
            const response = await JobApi.getLoggedJobs(depot, config);

            commit(mutation.SET_LOGGED_JOBS, { jobs: response.data.data });
        }
        catch (error) {
            commit(mutation.CLEAR_LOGGED_JOBS);
            commit(mutation.SET_LOGGED_JOBS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_LOGGED_JOBS_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all sub-states for the job state specified.
     * @param {*} goldState - the gold state.
     */
    async fetchSubStates({ commit }, goldState) {
        commit(mutation.CLEAR_JOB_SUB_STATES_ERROR);
        commit(mutation.SET_JOB_SUB_STATES_LOADING, { loading: true })

        try {
            const response = await JobStateApi.getSubStates(goldState);

            commit(mutation.SET_JOB_SUB_STATES, { states: response.data.data });
        }
        catch(error) {
            commit(mutation.SET_JOB_SUB_STATES_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_JOB_SUB_STATES_LOADING, { loading: false });
        }
    },

    /**
    * Fetches all checklists for the specified job
    * stores them in vuex.
    * 
    * @param {string} jobId - The ID of the job.
    */
    async fetchChecklists({ commit }, jobId) {
        commit(mutation.CLEAR_CHECKLISTS_ERROR);
        commit(mutation.SET_CHECKLISTS_LOADING, { loading: true })

        try {
            const response = await ChecklistApi.getAllChecklistsByJob(jobId);
            commit(mutation.SET_CHECKLISTS, { checklists: response.data.data });
        }
        catch(error) {
            commit(mutation.SET_CHECKLISTS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_CHECKLISTS_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all mileage records for the specified job.
     * 
     * @param {*} jobId - the job ID.
     */
    async fetchMileage({ commit, getters }, jobId) {
        commit(mutation.CLEAR_MILEAGES_ERROR);
        commit(mutation.SET_MILEAGES_LOADING, { loading : true });

        try {
            const response  = await JobApi.getMileageRecords(jobId);
            const mileages  = addEngineerName(getters, response.data.data);
            commit(mutation.SET_MILEAGES, { mileages });
        }
        catch (error) {
            commit(mutation.SET_MILEAGES_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_MILEAGES_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all notes records for the specified job.
     * 
     * @param {*} jobId - the job ID.
     */
    async fetchNotes({ commit, getters }, jobId) {
        commit(mutation.CLEAR_NOTES_ERROR);
        commit(mutation.SET_NOTES_LOADING, { loadng : true });

        try {
            const response  = await JobApi.getNotesRecords(jobId);
            const notes     = addEngineerName(getters, response.data.data);
            commit(mutation.SET_NOTES, { notes });
        }
        catch (error) {
            commit(mutation.SET_NOTES_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_NOTES_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all serial number records for the specified job.
     * 
     * @param {*} jobId - the job ID.
     */
    async fetchSerialNumbers({ commit, getters }, jobId) {
        commit(mutation.CLEAR_SERIAL_NUMBER_ERROR);
        commit(mutation.SET_SERIAL_NUMBER_LOADING, { loading : true });

        try {
            const response      = await JobApi.getSerialNumberRecords(jobId);
            // Remove records with a blank serial number.
            let serialNumbers   = _filter(response.data.data, s => { return s.SerialNumber; });
            serialNumbers       = addEngineerName(getters, serialNumbers);
            commit(mutation.SET_SERIAL_NUMBER, { serialNumbers });
        }
        catch (error) {
            commit(mutation.SET_SERIAL_NUMBER_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_SERIAL_NUMBER_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all the signature records for the specified job.
     * 
     * @param {*} jobId - the job ID.
     */
    async fetchSignatures({ commit, getters }, jobId) {
        commit(mutation.CLEAR_SIGNATURES_ERROR);
        commit(mutation.SET_SIGNATURES_LOADING, { loading : true });

        try {
            const response      = await JobApi.getSignatureRecords(jobId);
            const signatures    = addEngineerName(getters, response.data.data);
            commit(mutation.SET_SIGNATURES, { signatures });
        }
        catch (error) {
            commit(mutation.SET_SIGNATURES_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_SIGNATURES_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all the clock hours records for the specified job.
     * 
     * @param {string} jobId - the job ID.
     */
    async fetchClockHours({ commit, getters }, jobId) {
        commit(mutation.CLEAR_CLOCK_HOURS_ERROR);
        commit(mutation.SET_CLOCK_HOURS_LOADING, { loading : true });

        try {
            const response      = await JobApi.getClockHoursRecords(jobId);
            const clockHours    = addEngineerName(getters, response.data.data);
            commit(mutation.SET_CLOCK_HOURS, { clockHours });
        }
        catch (error) {
            commit(mutation.SET_CLOCK_HOURS_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_CLOCK_HOURS_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all engineers associated to the job.
     * 
     * @param {*} jobId - the job ID.
     */
    async fetchEngineers({ commit }, jobId) {
        commit(mutation.CLEAR_ENGINEERS_ERROR);
        commit(mutation.SET_ENGINEERS_LOADING, { loading : true });

        try {
            const response = await JobApi.getEngineersForJob(jobId);
            commit(mutation.SET_ENGINEERS, { engineers : response.data.data });
        }
        catch (error) {
            commit(mutation.SET_ENGINEERS_ERROR, { error : error.response.data.Message || error.response.data.message });
        }
        finally {
            commit(mutation.SET_ENGINEERS_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all wholegoods for the associated customer.
     * 
     * @param {String} accountNo - the customer account number.
     */
    async fetchCustomerWholegoods({ commit }, accountNo) {
        commit(mutation.CLEAR_CUSTOMER_WHOLEGOODS);
        commit(mutation.SET_CUSTOMER_WHOLEGOODS_LOADING, { loading : true });

        try {
            const response = await WholegoodApi.getWholegoodsByCustomer(accountNo);
            commit(mutation.SET_CUSTOMER_WHOLEGOODS, { wholegoods : response.data.data });
        }
        catch (error) {
            commit(mutation.SET_CUSTOMER_WHOLEGOODS_ERROR, { error : error });
        }
        finally {
            commit(mutation.SET_CUSTOMER_WHOLEGOODS_LOADING, { loading : false });
        }
    },

    /**
     * Fetch all customers for a given job.
     * 
     * @param {string} jobId - the job ID.
     */
    async fetchComments({ commit }, jobId) {
        commit(mutation.CLEAR_COMMENTS);
        commit(mutation.SET_COMMENTS_LOADING, { loading : true });

        try {
            const response = await JobApi.getComments(jobId);
            commit(mutation.SET_COMMENTS, { comments : response.data.data });
        }
        catch (error) {
            commit(mutation.SET_COMMENTS_ERROR, { error : error.response.data.message || error.response.data.Message });
        }
        finally {
            commit(mutation.SET_COMMENTS_LOADING, { loading : false });
        }
    },

    async updateChecklist({ commit }, checklist) {
        commit(mutation.CLEAR_CHECKLISTS_ERROR);
        commit(mutation.SET_CHECKLISTS_LOADING, { loading: true })

        try {
            const response = await ChecklistApi.updateChecklist(checklist.JobId, checklist);
            const updated      = response.data.data;

            commit(mutation.UPDATE_CHECKLIST, updated);
        }
        catch(error) {
            commit(mutation.SET_CHECKLISTS_ERROR, { error: error.message });
        }
        finally {
            commit(mutation.SET_CHECKLISTS_LOADING, { loading: false });
        }
    },


    /**
     * Reset the store module to its initial state.
     */
    reset({ commit }) {
        commit(mutation.RESET);
    },

    /**
     * Reset the currently loaded job and associated data.
     */
    clearCurrentJob({ commit }) {
        commit(mutation.CLEAR_JOB);
        commit(mutation.CLEAR_JOB_ERROR);
        commit(mutation.CLEAR_TASKS);
        commit(mutation.CLEAR_TASKS_ERROR);
        commit(mutation.CLEAR_ACTIVITIES);
        commit(mutation.CLEAR_ACTIVITIES_ERROR);
        commit(mutation.CLEAR_COMPLETIONS);
        commit(mutation.CLEAR_COMPLETIONS_ERROR);
        commit(mutation.CLEAR_JOB_SUB_STATES);
        commit(mutation.CLEAR_JOB_SUB_STATES_ERROR);
        commit(mutation.CLEAR_CHECKLISTS);
        commit(mutation.CLEAR_CHECKLISTS_ERROR);
        commit(mutation.CLEAR_CLOCK_HOURS);
        commit(mutation.CLEAR_CLOCK_HOURS_ERROR);
        commit(mutation.CLEAR_MILEAGES);
        commit(mutation.CLEAR_MILEAGES_ERROR);
        commit(mutation.CLEAR_SERIAL_NUMBER);
        commit(mutation.CLEAR_SERIAL_NUMBER_ERROR);
        commit(mutation.CLEAR_NOTES);
        commit(mutation.CLEAR_NOTES_ERROR);
        commit(mutation.CLEAR_SIGNATURES);
        commit(mutation.CLEAR_SIGNATURES_ERROR);
        commit(mutation.CLEAR_ENGINEERS);
        commit(mutation.CLEAR_ENGINEERS_ERROR);
        commit(mutation.CLEAR_CUSTOMER_WHOLEGOODS);
        commit(mutation.CLEAR_CUSTOMER_WHOLEGOODS_ERROR);
        commit(mutation.CLEAR_COMMENTS);
        commit(mutation.CLEAR_COMMENTS_ERROR);
    },

    /**
     * Reset the currently loaded active and logged jobs.
     */
    clearJobs({ commit }) {
        commit(mutation.CLEAR_ACTIVE_JOBS);
        commit(mutation.CLEAR_LOGGED_JOBS);
    },

    /**
     * Store the current jobs kanban filter in vuex.
     * @param {*} filter - the filter to store.
     */
    storeJobsKanbanFilter({ commit }, filter) {
        commit(mutation.SET_JOBS_KANBAN_SEARCH, { filter });
    },
    
    /**
     * Move the specified job into the logged jobs collection.
     * 
     * @param {Object} job - the job.
     */
    updateLoggedJob({ state, commit }, job) {
        let activeJobs = state.activeJobs.value.filter((activeJob) => job.Id !== activeJob.Id);
        let loggedJobs = [...state.loggedJobs.value];

        loggedJobs.push(job);
        
        commit(mutation.SET_ACTIVE_JOBS, { jobs : activeJobs });
        commit(mutation.SET_LOGGED_JOBS, { jobs : loggedJobs });
    },

    storeJob({ commit }, job) {
        commit(mutation.SET_JOB, { job });
    }
};

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
};
