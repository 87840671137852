<template>
<div>
    <div class="col-12">
        <header>
            <h3>Job Search</h3>
        </header>

        <!-- Error -->
        <b-alert variant="danger"
            dismissible
            :show="null !== error"
            @dismissed="error = null">
            <h5 class="alert-heading">Failed to Search</h5>
            <p class="mb-0">{{ error }}</p>
        </b-alert>

        <div class="d-flex justify-content-end">
            <help-panel>
			    <p>Here, you can search for any job with <em>Gold</em>.</p>
                <p>Start typing in the search box to automatically start searching, or click the <i class="fas fa-search"></i> to manually start a search.</p>
                
                <p>To apply filters to the search use the provided filter controls and then click the <i class="fas fa-search"></i>.
                    <ul>
                        <li><strong>To filter by depot;</strong> Select the desired depot from the drop down list. Select <em>All Depots</em> to search for jobs across all depots.</li>
                        <li><strong>To filter by engineer;</strong> Select the desired engineer from the drop down list. Select <em>All Engineers</em> to search for jobs assigned to any engineer.</li>
                        <li><strong>To filter by status;</strong> Select the desired job status from the drop down list. Select <em>All Statuses</em> to search for jobs with any status.</li>
                        <li><strong>To filter by date;</strong>
                            <ul>
                                <li>Filtering on the <strong> created date </strong> for the job;</li>
                                <li><strong>From;</strong> Select the desired <em>from</em> date using the date picker. If no date is specified all jobs within the last 3 months will be searched</li>
                                <li><strong>To;</strong> Select the desired <em>to</em> date using the date picker. If no date is specified all jobs between the <em>from</em> date and today will be searched</li>
                                
                            </ul>
                        </li>
                    </ul>
                </p>
            </help-panel>
        </div>

        <!-- Search Box -->
        <div class="row search pb-2">
            <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
                <div class="input-group">
                    <input ref="search"
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        v-model="searchTerm"
                        @input="debouncedSearch">
                    <div class="input-group-append"
                        @click="handleSearch()">
                        <button type="button"
                            class="btn ib-append-button">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Filters -->
        <div class="form-row filters pb-2">
            <!-- Depot -->
            <div class="col-4 col-lg">
                <depot-list />
            </div>
            <!-- Engineer -->
            <div class="col-4 col-lg">
                <ib-select-entry id="engineers"
                    :items="engineerList"
                    displayProperty="Name"
                    noItemsMessage="No engineers available"
                    v-model="filters.engineer"
                    @input="debouncedSearch"
                ></ib-select-entry>
            </div>
            <!-- Job Status -->
            <div class="col-4 col-lg">
                <ib-select-entry id="statuses"
                    :items="statusList"
                    noItemsMessage="No statuses available"
                    v-model="filters.status"
                    @input="debouncedSearch"
                ></ib-select-entry>
            </div>
            <!-- From Date -->
            <div class="col-4 col-lg offset-2 offset-lg-0 mt-2 mt-lg-0">
                <ib-date-picker id="from"
                    placeholder="From"
                    v-model="filters.fromDate"
                    @input="debouncedSearch"
                ></ib-date-picker>
            </div>
            <!-- To Date -->
            <div class="col-4 col-lg mt-2 mt-lg-0">
                <ib-date-picker id="to"
                    placeholder="To"
                    :minValue="filters.fromDate"
                    v-model="filters.toDate"
                    @input="debouncedSearch"
                ></ib-date-picker>
            </div>
        </div>

        <!-- Search Results -->
        <div class="results">
            <ib-table :items="table.jobs"
                :columns="table.columns"
                :itemsPerPage="10"
                :loading="searching"
                :noItemsMessage="dirty ? 'No jobs found.' : ''"
                loadingMessage="Searching..."
                v-model="table.selectedJob"
                @input="debouncedSearch">
                <!-- Setup job number as a link to view job details -->
                <template slot="Id"
                    slot-scope="data">
                    <b-link :to="{ name: 'EditJob', params: { id: data.item.Id } }">
                        {{ data.item.Id }}
                    </b-link>
                </template>
                <!-- Format required date -->
                <template slot="CreatedDate"
                    slot-scope="data">
                    {{ data.item.CreatedDate | formatDate() }}
                </template>

                
                <template slot="Flags"
                          slot-scope="data">
                    <div>
                        <div :id="`job-${data.item.Id}-hasDocs`"
                            v-if="$_.get(data, 'item.Flags.DOC_STATE') === 'Urgent'"">
                            <img 
                                class="document-status-icon-svg"
                                src='../../../assets/icon/document_r.svg'
                                alt="R"
                            />
                            <b-tooltip placement="left"
                                :target="`job-${data.item.Id}-hasDocs`"
                                title="Documents Expiring Soon">
                            </b-tooltip>
                        </div>
                        <div :id="`job-${data.item.Id}-hasDocs`"
                            v-else-if="$_.get(data, 'item.Flags.DOC_STATE') === 'Pending'">
                            <img 
                                
                                class="document-status-icon-svg"
                                src='../../../assets/icon/document_y.svg'
                                alt="Y"
                            />
                            <b-tooltip placement="left"
                                :target="`job-${data.item.Id}-hasDocs`"
                                title="Documents Pending">
                            </b-tooltip>
                        </div>
                    </div>
                </template>
            </ib-table>
        </div>
    </div>
</div>
</template>
    
<script>
import { mapState, mapGetters }              from 'vuex';
import JobsApi                   from '@WS/api/job';
import DateMixin, { DateFormat } from '@/mixins/DateMixin';
import HelpPanel                 from '@/components/HelpPanel';
import IbTable                   from '@/components/table/IbTable';
import IbSelectEntry             from '@/components/form/IbSelectEntry';
import IbDatePicker              from '@/components/form/IbDatePicker';
import { JobFlags }              from '@WS/common/job-flags';
import { JobStatusList }         from '@WS/common/job-status';
import DepotList                 from '@WS/components/DepotList';

const SEARCH_DELAY  = 800;
const ALL_DEPOTS    = "*";
const ALL_ENGINEERS = {
    Id:     -1,
    Name:   'All Engineers'
};
const ALL_STATUSES  = 'All Statuses';
const TABLE_COLUMNS = [
    {
        heading:        'ID',
        property:       'Id',
        sortable:       true,
        class:          'idCol'
    },
    {
        heading:        'Description',
        property:       'Description',
        sortable:       true,
        class:          'descriptionCol',
        hideOverflow:   true
    },
    {
        heading:        'Status',
        property:       'Status',
        sortable:       true,
        class:          'statusCol'
    },
    {
        heading:        'Engineer',
        property:       'EngineerName',
        sortable:       true,
        class:          'engineerCol',
        hideOverflow:   true
    },
    {
        heading:        'Created Date',
        property:       'CreatedDate',
        sortable:       true,
        class:          'requiredDateCol'
    },
    {
        heading:        'Account Name',
        property:       'AccountName',
        sortable:       true,
        class:          'accountNameCol',
        hideOverflow:   true
    },
    {
        heading:        'Account No',
        property:       'AccountNo',
        sortable:       true,
        class:          'accountNoCol'
    },
    {
        heading:        '',
        property:       'Flags',
        sortable:       false,
        class:          'flagsCol',
        hideOverflow:   false
    }
];

export default {
    name: 'JobSearch',
    
    mixins: [
        DateMixin
    ],
    
    components: {
        DepotList,
        HelpPanel,
        IbTable,
        IbSelectEntry,
        IbDatePicker
    },
    
    props: {
        depots: {
            type:               Object,
            default() {
                // Object definition passed from Workshop.vue.
                return {
                    value:      [],
                    error:      null,
                    loading:    false
                }
            }
        },
    },
    
    data() {
        return {
            searchTerm:     null,
            searching:      false,
            error:          null,
            dirty:          false,

            filters: {
                engineer:   {},
                status:     '',
                fromDate:   '',
                toDate:     ''
            },

            table: {
                columns:    TABLE_COLUMNS,
                jobs:       []
            },

            HAS_DOCS: JobFlags.HAS_DOCS
        }
    },

    created() {
        this.initialiseFilters();
        this.debouncedSearch();
    },
    
    computed: {
        ...mapState('engineer', {
            engineers: 'engineers',
        }),

        ...mapGetters('system', {
            currentDepotId : 'currentDepotId'
        }),

        /**
         * Returns an array of engineers starting with an 'All Engineers' option.
         * If no engineers are loaded, returns an empty array.
         */
        engineerList() {
            const engineers = this.buildSelectList(this.engineers.value, ALL_ENGINEERS);
            return engineers;
        },

        /**
         * Returns an array of job status starting with an 'All Statuses' option.
         */
        statusList() {
            const statuses = this.buildSelectList(JobStatusList, ALL_STATUSES);
            return statuses;
        },

        /**
         * Returns the ID of the selected depot, otherwise null if the selected
         * depot is 'All Depots'.
         */
        depotFilter() {
            let filter = null;
            if (ALL_DEPOTS != this.currentDepotId) {
                filter = this.currentDepotId;
            }
            return filter;
        },

        /**
         * Returns the ID of the selected engineer, otherwise null if the selected
         * engineer is 'All Engineers'.
         */
        engineerFilter() {
            let filter = null;
            if (ALL_ENGINEERS !== this.filters.engineer) {
                filter = this.filters.engineer.Id;
            }
            return filter;
        },

        /**
         * Returns the selected job status, otherwise null if the selected status
         * is 'All Statuses'.
         */
        statusFilter() {
            let filter = null;
            if (ALL_STATUSES !== this.filters.status) {
                filter = this.filters.status;
            }
            return filter;
        },

        /**
         * Delay peforming a search until the specified time period has expired.
         * Subsequent calls to the function within the time period will reset
         * the delay.
         */
        debouncedSearch() {
            const me = this;
            return me.$_.debounce(() => me.performJobSummariesSearch(), SEARCH_DELAY);
        }
    },

    watch:{
        //if the company changes then the list of engineers changes also, 
        //so reset the filters and trigger a search
        engineerList(){
            this.initialiseFilters();
            this.debouncedSearch();
        },

        depotFilter() {
            this.debouncedSearch();
        }
    },
    
    methods: {
        /**
         * Set all filters to their default values.
         */
        initialiseFilters() {
            this.filters.engineer   = ALL_ENGINEERS;
            this.filters.status     = ALL_STATUSES;
            this.filters.toDate     = this.getDate(new Date());
            this.filters.fromDate   = this.subtractMonths(3, this.filters.toDate);
        },

        /**
         * Returns a new array with defaultItem at index 0 and all items specified
         * in array appended afterwards.
         */
        buildSelectList(array, defaultItem) {
            let items = []
            if (Array.isArray(array) && (0 < array.length)) {
                // Create new array with defaultItem as first item.
                items.push(defaultItem);
                items.push(...array);
            }
            return items;
        },

        /**
         * Perform a search for job summaries on the API server using
         * the specified search term and filters.
         */
        performJobSummariesSearch() {
            const me        = this;
            me.error        = null;
            me.searching    = true;
            me.dirty        = true;

            JobsApi
                .searchJobSummaries(
                    me.searchTerm,
                    this.depotFilter,
                    this.engineerFilter,
                    this.statusFilter,
                    this.filters.fromDate,
                    this.filters.toDate
                )
                .then(
                    success => {
                        me.table.jobs   = success.data.data;
                    },
                    failure => {
                        let error = "Failed to search for jobs. ";
                        // Get message from response, if there is one.
                        if (failure.response && failure.response.data && failure.response.data.hasOwnProperty('message')) {
                            error += failure.response.data.message;
                        }
                        else {
                            error += failure.message;
                        }
                        console.error(error);
                        me.table.jobs   = [];
                        me.error        = error;
                    }
                )
                .finally(() => {
                    me.searching = false;
                });
        },

        /**
         * Clears any pending debounced search and then triggers an immediate
         * search.
         */
        handleSearch() {
            // Clear any pending debounced search.
            const debounced = this.debouncedSearch;
            debounced.cancel();
            this.performJobSummariesSearch();
        }
    }
}
</script>
    
<style lang="less" scoped>
@import '../../../assets/styles/ib-common';

// Table column classes.
/deep/ .idCol {
    min-width: 85px;
}
/deep/ .descriptionCol {
    min-width: 130px;
    width: 40%;
}
/deep/ .statusCol {
    min-width: 100px;
}
/deep/ .engineerCol {
    min-width: 115px;
    width: 20%;
}
/deep/ .requiredDateCol {
    min-width: 180px;
}
/deep/ .accountNameCol {
    min-width: 150px;
    width: 40%;
}
/deep/ .accountNoCol {
    min-width: 130px;
}
/deep/ .flagsCol {
    min-width: 50px;
}
.flag-icon {
    background-color: gold;
}

.document-status-icon-svg {
    height: 1.4em;
    padding-right: 10px;
}
</style>